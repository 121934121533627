import { useEffect, useState } from 'react'

import { InvoiceStatus } from 'graph/generated/payments/graphql-types'
import Cookies from 'js-cookie'
import { NextPage } from 'next'
import { useRouter } from 'next/router'
import { AppCookie, getCookieExpires } from 'utils/cookies'

import { useCompanyAndInvoiceContext } from '@/hooks/providers/CompanyAndInvoiceProvider'
import useActiveUserInfo from '@/hooks/useActiveUserInfo'
import LoginWithGuest from '@/layouts/PayFlowLayout/LoginWithGuest'

const InvoiceLanding: NextPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { user, loading: loadingUser } = useActiveUserInfo()
  const { setPayAsGuest, invoice } = useCompanyAndInvoiceContext()
  const { push, query } = useRouter()
  const customerId = query?.cid as string

  const invoiceStatus = invoice?.status
  const isInvoicePayable =
    invoiceStatus === InvoiceStatus.Overdue ||
    invoiceStatus === InvoiceStatus.Upcoming

  const redirectUrl = `${window.location.pathname}/payment`
  const isLoggedIn = user && !loadingUser

  useEffect(() => {
    Cookies.remove(AppCookie.LoggedAsGuest)
    setPayAsGuest(false)
  }, [])

  useEffect(() => {
    const isLoggedAsCustomer = isLoggedIn && user.role !== 'guest'

    if (isLoggedAsCustomer) {
      if (isInvoicePayable) {
        push(redirectUrl)
      } else {
        setIsLoading(false)
      }
    }

    if (!loadingUser && !user) {
      setIsLoading(false)
    }
  }, [user, isLoggedIn, loadingUser, isInvoicePayable])

  useEffect(() => {
    if (customerId) {
      Cookies.set(AppCookie.CustomerId, customerId, {
        expires: getCookieExpires(AppCookie.CustomerId),
        httpOnly: false
      })
    }
  }, [customerId])

  return (
    <LoginWithGuest redirectUrl={redirectUrl} showSkeletonLoading={isLoading} />
  )
}

export default InvoiceLanding
